import './TaxSlabs.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Row, Col, Container } from 'react-bootstrap';

const TaxSlabs = () => {

    return(
        <>
        <Header title="Salary Tax Slabs According to Budget 2024-2025" />
        <Container className="mt-5 mb-3 pt-5">
            <Row>
                <Col>
                <h1>Salary Tax Slabs According to Budget 2024-2025</h1>
                <ul className="slabs">
                    <li>
                        If the annual salary income does not exceed PKR. <strong>600,000</strong> then the rate of the income tax is <strong>0%</strong>
                    </li>
                    <li>
                        If the annual salary income exceeds PKR. 600,000 but does not exceed PKR. <strong>1.2 million</strong> then the rate of income tax is <strong>5%</strong> of the amount exceeding PKR. 600,000.
                    </li>
                    <li>
                        If the taxable annual income exceeds PKR. 1.2 million but does not exceed PKR. <strong>2.2 million</strong> then the rate of income tax is <strong>PKR. 30,000 + 15%</strong> of the amount exceeding PKR. 1.2 million.
                    </li>
                    <li>
                        If the annual salary income exceeds PKR. 2.2 million but does not exceed PKR. <strong>3.2 million</strong> then the rate of income tax is flat <strong>PKR 180,000 + 25%</strong> of the amount exceeding PKR. 2.2 million
                    </li>
                    <li>
                    If the annual salary income exceeds PKR. 3.2 million but does not exceed PKR. <strong>4.1 million</strong> then the rate of income tax is flat <strong>PKR. 430,000 + 30%</strong> of the amount exceeding PKR. 3.2 million
                    </li>
                    <li>
                    If the annual salary income exceeds PKR. 4.1 million then the rate of income tax is flat <strong>PKR 700,000 + 35%</strong> of the amount exceeding PKR. 4.1 million
                    </li>
                </ul>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    );
}

export default TaxSlabs;