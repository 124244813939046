import React from "react";
import "./Footer.css";

import { Row, Col, Container } from 'react-bootstrap';

const Footer = () => {

  return (
        <Container className="fixed-bottom top-border pt-3 bg-white">
            <Row>
                <Col lg="6" xs>
                <p>Copyright 2023. All Right Reserved</p>
                </Col>
                <Col lg="6" xs className="right">
                    <a href="privacy-policy" >Privacy Policy</a>
                </Col>
                
            </Row>
               
        </Container>
  );
};

export default Footer;