import './TaxCalculator.css';
import { Tabs, Tab, Row, Col, Container } from 'react-bootstrap';
import { useState } from 'react';

import Header from '../Header/Header.js';
import Footer from '../Footer/Footer';
import DynamicSalaryComponent from './DynamicSalaryComponent/DynamicSalaryComponent.js';

const TaxCalculator = () => {
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [annualSalary, setAnnualSalary] = useState(0);
  const [monthlyTax, setMonthlyTax] = useState(0);
  const [annualTax, setAnnualTax] = useState(0);
  const [monthlySalaryAfterTax, setMonthlySalaryAfterTax] = useState(0);
  const [annualSalaryAfterTax, setAnnualSalaryAfterTax] = useState(0);
  const [months, setMonths] = useState([
    {nume: 1, title: 'July', value:0}, 
    {nume: 2, title: 'August', value:0}, 
    {nume: 3, title: 'September', value:0}, 
    {nume: 4, title: 'October', value:0}, 
    {nume: 5, title: 'November', value:0}, 
    {nume: 6, title: 'December', value:0},
    {nume: 7, title: 'January', value:0}, 
    {nume: 8, title: 'February', value:0}, 
    {nume: 9, title: 'March', value:0}, 
    {nume: 10, title: 'April', value:0}, 
    {nume: 11, title: 'May', value:0}, 
    {nume: 12, title: 'June', value:0}
  ]);

  const calculateSalary = (event) => {
    //console.log(event.target.value);
    let monthly = event.target.value;
    let annual = monthly*12;
    var annual_tax = calculateTax(annual);
    var monthly_tax = annual_tax/12;
    var monthly_salary_before_tax = monthly-monthly_tax;
    var annual_salary_before_tax = annual-annual_tax;

    setMonthlySalary(monthly);
    setAnnualSalary(annual);
    setMonthlyTax(Math.round(monthly_tax));
    setAnnualTax(Math.round(annual_tax));
    setMonthlySalaryAfterTax(Math.round(monthly_salary_before_tax));
    setAnnualSalaryAfterTax(annual_salary_before_tax);
  }

  const calculateAnnualSalary = (event) => {
    let annual = event.target.value;
    let monthly = annual/12;
    var annual_tax = calculateTax(annual);
    var monthly_tax = annual_tax/12;
    var monthly_salary_before_tax = monthly-monthly_tax;
    var annual_salary_before_tax = annual-annual_tax;

    setMonthlySalary(monthly);
    setAnnualSalary(annual);
    setMonthlyTax(monthly_tax);
    setAnnualTax(annual_tax);
    setMonthlySalaryAfterTax(monthly_salary_before_tax);
    setAnnualSalaryAfterTax(annual_salary_before_tax);
  }

  const calculateDynamicSalary = (event) => {
    //console.log(event.target.value);
    
    event.preventDefault();
    let updated_months = [];
    let month_index = parseInt(event.target.name);
    updated_months = months.map((m) => {
      if(m.nume >= month_index)
      {
        m.value = event.target.value;
      }
      return m;
    });
    //console.log(updated_months);
    setMonths(updated_months);
    
    let annual = summitionDynamicSalary();
    let monthly = annual/12;
    var annual_tax = calculateTax(annual);
    var monthly_tax = annual_tax/12;
    var monthly_salary_before_tax = monthly-monthly_tax;
    var annual_salary_before_tax = annual-annual_tax;

    setMonthlySalary(monthly);
    setAnnualSalary(annual);
    setMonthlyTax(monthly_tax);
    setAnnualTax(annual_tax);
    setMonthlySalaryAfterTax(monthly_salary_before_tax);
    setAnnualSalaryAfterTax(annual_salary_before_tax);
  }

  const summitionDynamicSalary = () => {
    let salary = 0;
    months.map((m) => {
      salary += parseInt(m.value);
      return salary;
    });

    return salary;
  }

  /* const taxSlabs = [
    {min: 0, max: 600000, fixAmount:0, percentExceed: 0},
    {min: 600000, max: 1200000, fixAmount:0, percentExceed: 2.5},
    {min: 1200000, max: 2400000, fixAmount:15000, percentExceed: 12.5},
    {min: 2400000, max: 3600000, fixAmount:165000, percentExceed: 22.5},
    {min: 3600000, max: 6000000, fixAmount:435000, percentExceed: 27.5},
    {min: 6000000, max: 'infinite', fixAmount:1095000, percentExceed: 35}
  ]; */

  const taxSlabs = [
    {min: 0, max: 600000, fixAmount:0, percentExceed: 0},
    {min: 600000, max: 1200000, fixAmount:0, percentExceed: 5},
    {min: 1200000, max: 2200000, fixAmount:30000, percentExceed: 15},
    {min: 2200000, max: 3200000, fixAmount:180000, percentExceed: 25},
    {min: 3200000, max: 4100000, fixAmount:430000, percentExceed: 30},
    {min: 4100000, max: 'infinite', fixAmount:700000, percentExceed: 35}
  ];

  const calculateTax = (salary) => {
    let tax = 0;
    let taxable_amount = 0;
    taxSlabs.forEach((slab) => {
      if(slab.max === 'infinite')
      {
        if(salary > slab.min)
        {
          taxable_amount = salary - slab.min;
          tax = slab.fixAmount+(taxable_amount*slab.percentExceed/100);
        }
      }
      else
      {
        if(salary > slab.min && salary <= slab.max)
        {
          taxable_amount = salary - slab.min;
          tax = slab.fixAmount+(taxable_amount*slab.percentExceed/100);
        }
      }
    });
    return tax;
  }

  return (
    <>
    <Header title="Tax Calculator" />
    <Container className="mt-6 mb-3 pt-8">
          <Row>
            <Col xs lg="6">
              <Tabs defaultActiveKey="fixedSalary">
                <Tab eventKey="fixedSalary" title="Fixed Salary">
                  <div className="mt-5" style={{paddingLeft : '30px', paddingRight: '30px'} }>
                    <p>Fixed Salary means you have same salary per month</p>
                    <Row>
                      <Col className="answer-title">Your Monthly Salary</Col>
                      <Col>
                        <input type="text" className="form-control" onChange={calculateSalary} value={monthlySalary} />
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="dynamicSalary" title="Dynamic Salary">
                  <div className="mt-5" style={{paddingLeft : '30px', paddingRight: '30px'} }>
                  <p>Dynamic Salary means you have different salary per month, it consider last salary as the next all months salary</p>
                
                    {
                      months.map((m) => {
                        return <DynamicSalaryComponent name={m.nume} title={m.title} value={m.value} change={calculateDynamicSalary} />
                      })
                    }
                  </div>
                </Tab>
                <Tab eventKey="annualSalary" title="Annual Salary">
                  <div className="mt-5" style={{paddingLeft : '30px', paddingRight: '30px'} }>
                    <p>Here you calulate per your annual salary</p>
                    <Row>
                      <Col className="answer-title">Your Annual Salary</Col>
                      <Col>
                        <input type="text" className="form-control" onChange={calculateAnnualSalary} />
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
              <div className="result">
                
                <Row className="mr-5">
                  <Col className="answer-title">Annual Salary</Col>
                  <Col className="answer-value">{annualSalary}</Col>
                </Row>
                <Row className="mr-5">
                  <Col className="answer-title">Monthly Tax</Col>
                  <Col className="answer-value">{monthlyTax}</Col>
                </Row>
                <Row className="mr-5">
                  <Col className="answer-title">Annual Tax</Col>
                  <Col className="answer-value">{annualTax}</Col>
                </Row>
                <Row className="mr-5">
                  <Col className="answer-title">Monthly Salary after Tax</Col>
                  <Col className="answer-value">{monthlySalaryAfterTax}</Col>
                </Row>
                <Row className="mr-5">
                  <Col className="answer-title">Annual Salary after Tax</Col>
                  <Col className="answer-value">{annualSalaryAfterTax}</Col>
                </Row>
              </div>
            </Col>
          </Row>
    </Container>
    <Footer />
    </>
  );
}

export default TaxCalculator;
