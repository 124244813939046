import './AboutUs.css';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer';
import { Row, Col, Container } from 'react-bootstrap';

const AboutUs = () => {

    return(
        <>
        <Header title="About US" />
        <Container className="mt-5 mb-3 pt-5">
            <Row>
                <Col>
                <h1>About Me</h1>
                    <h3>Hi, My Name is Zia Abbas.</h3>
                    <p>Since 2005, I’ve had the pleasure of working with some great companies and great projects, working side by side to web design and development, new apps and improve upon existing products.</p>
                    <p>I'm a passionate software engineer with a knack for turning ideas into functional and user-friendly applications.</p>
                    <p>My expertise lies in Mobile and Web Technologies.  I enjoy solving puzzles and translating complex problems into elegant code solutions. Collaborating with cross-functional teams is where I thrive, and I believe in continuous learning to stay at the forefront of technology. When I'm not coding, you can find me exploring new programming languages, contributing to open-source projects, or enjoying a good cup of coffee while brainstorming my next big coding challenge.</p>
                    <p>#Programmer #WebDeveloper #SolutionProvider #TechnicalArchitect #Laravel #PHP #MYSQL #Javascript #React #Angular #NextJS #AWS #Cloud #UI/UX #CodeIgnitor #Zend #Magento</p>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    );
}

export default AboutUs;