import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";


const Header = ({ title }) => {

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            Tax Calculator
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
                <Nav.Link href="/">Tax Calculator</Nav.Link>
                <Nav.Link href="tax-slabs">Tax Slabs</Nav.Link>
                <Nav.Link href="about-me">About Me</Nav.Link>
                <Nav.Link href="contact-us">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;