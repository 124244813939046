import './App.css';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import TaxCalculator from './TaxCalculator/TaxCalculator';
import TaxSlabs from './TaxSlabs/TaxSlabs';
import AboutUs from './AboutUs/AboutUs';
import ContactUs from './ContactUs/ContactUs';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';

const App = () => {
  return (
    <div className="main">
      <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<TaxCalculator />}>
              <Route index element={<TaxCalculator />} />
            </Route>
            <Route path="tax-slabs" element={<TaxSlabs />} />
            <Route path="about-me" element={<AboutUs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
    </div>
    
  );
}

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default App;
