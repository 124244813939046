import { Row, Col } from 'react-bootstrap';

function DynamicSalaryComponent(props){

    const title = props.title;
    const name = props.name;
    const value = props.value;
    const change = props.change;

return (
    <Row>
        <Col className="answer-title">{title} Salary</Col>
        <Col>
            <input type="text" className="form-control" name={name} onChange={change} value={value} />
        </Col>
    </Row>
);


} 

export default DynamicSalaryComponent;