import './ContactUs.css';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer';
import { Row, Col, Container, Form } from 'react-bootstrap';

const ContactUs = () => {

    const endpoint = "https://fv0njm69i9.execute-api.us-west-2.amazonaws.com/default/emailSender";
    const send_email = () =>{
        
    }

    return(
        <>
        <Header title="Contact US" />
        <Container className="mt-5 mb-3 pt-5">
            <Row>
                <Col xs lg="6">
                <h1>Contact Us</h1>
                <Form>
                    <Form.Group className="mb-3" controlId="fullname">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="subject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="message">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="submitform">
                        <button type="submit" className="btn btn-primary" onClick={send_email}>Submit</button>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    );
}

export default ContactUs;